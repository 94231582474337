import { template as template_998cccf5604a4c22a8853c0570726e20 } from "@ember/template-compiler";
const FKControlMenuContainer = template_998cccf5604a4c22a8853c0570726e20(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
