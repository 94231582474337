import { template as template_160b6725a58e410fa4b88efea72c1dc5 } from "@ember/template-compiler";
const FKText = template_160b6725a58e410fa4b88efea72c1dc5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
